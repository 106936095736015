<template>
  <el-carousel v-loading="loading">            
    <el-carousel-item v-for="(item,index) in imgData" :key="index">
      <img :src="item.fileUrl" />
    </el-carousel-item>
    <!-- <el-carousel-item><img :src="require('@/assets/images/ad1.jpg')" /></el-carousel-item>
    <el-carousel-item><img :src="require('@/assets/images/ad2.jpg')" /></el-carousel-item>
    <el-carousel-item><img :src="require('@/assets/images/ad3.jpg')" /></el-carousel-item> -->
  </el-carousel>
</template>

<script>
export default {
  data () {
    return {
      loading: false,
      // imgList: [], // 读取的轮播图片列
      imgData: null
    }
  },
  mounted () {
    // 滚动图片
    // this.getadPic()
    this.getData()
  },
  methods: {
    async getData () {
      var en = {
        "condition": {"parentOrgId":"c4ea4992ffc14621af7953512a94bda9"},
        "number": 1,
        "relation": "and",
        "size": 100,
        "sortDirection": "ASC",
        "sortProperties": ["createDate"]       
      }
      const { data: res } = await this.$http.put(this.$api.homepageImage, en) 
      // console.log('homepageImage', res)
      if (res.state.code === 10000) {
        if (res.body.list.length > 0) {
          this.getListData(res.body.list[0].id)
        } else {
          console.log('没有图片')
        }        
      } else {
        this.$message.error(res.state.message)
      }
    },
    // 明细
    async getListData (id) {
      // this.loading = true
      var en = {
        "condition": {
            "id": "is not null",
            "businessId":id,
            "original":"PC"
        },
        "number": 0,
        "relation": "and",
        "size": 5000,
        "sortDirection": "ASC",
        "sortProperties": ["createDate"]
      }
      const { data: res } = await this.$http.put(this.$api.attachment, en) 
      // console.log('attachment', res)
      if (res.state.code === 10000) {        
        this.imgData = res.body.list        
        // var imgs = []
        // res.body.list.forEach(p => {          
        //   var img = p.fileUrl 
        //   // this.imgList.push(img)
        //   imgs.push({
        //     img: img
        //   })  
        // }) 
        // console.log('this.imgList=', imgs)        
        // // 获取base64编码图片保存在本地
        // this.getImg(imgs)
      } else {
        this.$message.error(res.state.message)
      }
      // 延时1.5秒
      // setTimeout(()=>{
      //   this.loading = false
      // }, 1500)      
    },
    // 获取本地保存的ad,base64编码图片
    getadPic() {
      var imgs = null
      try {
        imgs = this.$storage.local.get('adPic')
      }
      catch (e) {
        //console.log("Storage failed: " + e)
        imgs = ['/images/ad1.jpg']
      }      
      this.imgData = imgs      
    }, 
    // 获取base64编码图片保存在本地
    async getImg(dList) {
      console.log("dList= ", dList)
      var imgs = []
      // console.log('dList=', dList) // dList.length
      for (var i = 0; i < dList.length; i++) {
        // var url = 'http://localhost:8081/images/ad1.jpg'
        var img = await this.url2DataURL(dList[i].img)
        // imgs.push(img)
        imgs.push({
          img: img
          // remark: dList[i].remark
        })
      }
      // this.$nextTick(() => {
      this.imgData = imgs
      // })
      // 本地保存
      this.$storage.local.set('adPic=', imgs)
    },   
    async url2DataURL(url) {
      var img = await this.url2Image(url) 
      var canvas = this.image2Canvas(img)
      var dataURL = canvas.toDataURL(canvas)
      return dataURL
    },
    // Url/Data-URL/Object-URL 转 Image
    url2Image(url) {
      return new Promise(resolve => {
        const image = new Image() 
        image.crossOrigin = "anonymous" 
        image.onload = () => {
          resolve(image)
        }
        image.src = url
      })
    },
    // Image 转 Canvas
    image2Canvas(image) {
      const canvas = document.createElement('canvas')
      const ctx = canvas.getContext('2d')
      canvas.width = image.width
      canvas.height = image.height
      ctx.drawImage(image, 0, 0)
      return canvas
    }
  }
}
</script>

<style>

</style>