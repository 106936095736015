<!--农银通前10排名-->
<template>
  <!-- <div class="tit mb" :style="{fontSize: titFontSize + 'px' }">各乡镇流转数据  <span>（面积：亩，价格：元） </span></div> -->
  <div style="padding: 30px 50px;">
    <div class="flex-around hztj">
      <div>当日累计交易金额（元）
        <span>{{totalAmount}}</span>
      </div>
      <div>当日累计交易笔数
        <span>{{totalCount}}</span>
      </div>
    </div>
    <div class="tb">
        <li>          
          <span style="width: 25%;">交易时间</span>
          <span style="width: 25%;">商户名称</span>
          <span style="width: 15%;">摊位号</span> 
          <span style="width: 20%; text-align: center;">商品</span>
          <span style="width: 15%; text-align: right;color: #ffaa00">交易金额（元）</span>        
        </li>
    </div>
    <div id="box-scroll">
      <ul>
        <li v-for="(item, index) in allData" :key="index">          
          <span style="width: 25%;">{{item.tradeDate}}</span>
          <span style="width: 25%;">{{item.shopName}}</span>
          <span style="width: 15%;">{{item.boothNo}}</span>
          <span style="width: 20%; text-align: center;">{{item.goodsName}}</span>
          <span style="width: 15%; text-align: right; color: #ffaa00">{{item.tradeAmount}}</span>          
        </li>
      </ul>
    </div>
    <!-- <div id="box-scroll" @mouseover="mouseOver" @mouseleave="mouseLeave">
      <ul :class="{anim:animate==true}">
        <li v-for="(item, index) in allData" :key="index">
          <span style="width: 20%;">{{item.tradeDate}}</span>
          <span style="width: 30%;">{{item.shopName}}</span>
          <span style="width: 15%;">{{item.boothNo}}</span>
          <span style="width: 20%; text-align: center;">{{item.goodsName}}</span>
          <span style="width: 15%; text-align: right; color: #ffaa00">{{item.tradeAmount}}</span>          
        </li>
      </ul>
    </div> -->
  </div>
</template>

<script>
// import {formatDate} from '@/utils/datetime_utils.js'
import {formatCurrency} from '@/utils/chineseNum.js'
export default {
  data () {
    return {
      // areaId: '', // 我的地区
      // sdate: '', // 开始日期
      // edate: '', // 结束日期
      // animate: false,
      allData: [],
      // veh_num: 0,
      // allData: [
      //   { time: '2024-11-06 08:30:05', val1: '张三', val11: '05,06', val2: '果蔬', val3: 25.60 },
      //   { time: '2024-11-06 08:32:13', val1: '李勇', val11: '76', val2: '果蔬', val3: 135.50 },
      //   { time: '2024-11-06 08:41:21', val1: '王云', val11: '55', val2: '禽蛋', val3: 78.50 },
      //   { time: '2024-11-06 08:41:25', val1: '王建军', val11: '70', val2: '果蔬', val3: 93.65 },
      //   { time: '2024-11-06 08:43:31', val1: '方伟', val11: '26', val2: '肉类', val3: 137.50 },
      //   { time: '2024-11-06 08:50:45', val1: '干花店', val11: '103,104', val2: '果蔬', val3: 352.45 },
      //   { time: '2024-11-06 08:51:49', val1: '王思文', val11: '110', val2: '肉类', val3: 235.55 },
      //   { time: '2024-11-06 08:52:55', val1: '李零食店', val11: '16,17', val2: '果蔬', val3: 67.40 },
      //   { time: '2024-11-06 08:55:12', val1: '赵记烤鸡', val11: '47', val2: '禽蛋', val3: 361.80 },
      //   { time: '2024-11-06 08:56:26', val1: '许记凉菜', val11: '29', val2: '果蔬', val3: 72.10 },
      //   { time: '2024-11-06 08:56:42', val1: '孙佳君', val11: '68', val2: '禽蛋', val3: 217.65 },
      //   { time: '2024-11-06 08:57:53', val1: '朱莉', val11: '79', val2: '肉类', val3: 287.96 },
      //   { time: '2024-11-06 08:58:16', val1: '干菜店', val11: '36', val2: '果蔬', val3: 78.00 },
      //   { time: '2024-11-06 08:59:05', val1: '王豆腐', val11: '88', val2: '果蔬', val3: 212.00 },
      //   { time: '2024-11-06 09:00:24', val1: '陈东来', val11: '107', val2: '肉类', val3: 173.50 },
      //   { time: '2024-11-06 09:01:08', val1: '农家菜', val11: '128', val2: '果蔬', val3: 43.26 }
      // ],
      // 定时器标识
      timerId: null,
      totalAmount: 0, // 当日累计交易金额
      totalCount: 0 // 当日累计交易笔数
    }
  },
  // props: ['searchPara'], // 子接收父参数
  mounted () {
    this.getTradeSummaryData()
    this.getTradeDetailData()
    this.getData()    
    // this.startInterval()
  },
  beforeUnmount() {
    this.clear()
  },
  methods: {
    // startInterval () {
    //   if (!this.allData || this.allData.length < 10) {
    //     return
    //   }
    //   if (this.timerId) {
    //     clearInterval(this.timerId)
    //   }
    //   this.timerId = setInterval(() => {
    //     this.animate = true
    //     setTimeout(() => {
    //       this.allData.push(this.allData[0]) // 将数组的第一个元素添加到数组的
    //       this.allData.shift() // 删除数组的第一个元素
    //       this.animate = false // margin-top 为0 的时候取消过渡动画，实现无缝滚动
    //     }, 500)
    //   }, 2000)
    // },
    // // 鼠标移入
    // mouseOver () {
    //   clearInterval(this.timerId)
    // },
    // // 鼠标移出
    // mouseLeave () {
    //   this.startInterval()
    // },
    clear () {
      clearInterval(this.timerId)
    },
    getData () {
      if (this.timerId) {
        clearInterval(this.timerId)
      }
      // 5秒取一次数据
      this.timerId = setInterval(() => {        
        this.getTradeSummaryData()
        this.getTradeDetailData()
      }, 5000)
    },
    // 当天交易汇总笔数和金额
    async getTradeSummaryData () {
      var en = {
        position: "merchantTrade_queryTodayMerchantTradeSummary"
      }
      const { data: res } = await this.$http.post(this.$api.querySql, en) 
      // console.log('TradeSummary', res)
      if (res.state.code === 10000) {
        // this.$message.success(res.state.message) 
        this.totalAmount = formatCurrency(res.body[0].totalAmount, 2) // 当日累计交易金额    
        this.totalCount = formatCurrency(res.body[0].totalCount, 0)
      } else {
        this.$message.error(res.state.message)
      }
    },
    // 当天最新交易明细
    async getTradeDetailData () {
      var en = {
        position: "merchantTrade_queryTodayMerchantTradeDetail",        
        pageable: {
          pageNumber: 1,
          pageSize: 15
        }
      }
      const { data: res } = await this.$http.post(this.$api.querySqlByPage, en) 
      if (res.state.code === 10000) {
        // this.$message.success(res.state.message) 
        // 获取前15条数据
        // this.allData = res.body.slice(0,15)        
        this.allData = res.body.list
        // console.log('TradeDetail', res.body.list)
        // var i = 1
        this.allData.forEach(p => {
          // p.sn = i++          
          p.tradeAmount = formatCurrency(p.tradeAmount, 2)
        })              
      } else {
        this.$message.error(res.state.message)
      }
    }
  },
  watch: {
    // searchPara (newValue, oldValue) {
    //   // console.log('newValue3', newValue)
    //   // console.log('oldValue3', oldValue)
    //   if (newValue !== oldValue) {
    //     var str = newValue.split(',')
    //     this.areaId = str[0]
    //     this.sdate = str[1]
    //     this.edate = str[2]
    //     this.getData()
    //   }
    // }
  }
}
</script>

<style scoped>
.tit {
    color: #02a6b5;
    font-size: 16px;
    font-weight: bold;
    
  }
.tit span {
  font-weight:normal;
  font-size: 12px;
  color: #C4CBFE;
}
.tb li {  
  list-style: none;
  line-height: 40px;
  height: 40px;
  display: block;
  /* margin: 5px 0; */
  padding: 0 5px;
  /* color: #C4CBFE; */
  color: #000;  
  font-size: 18px;
  border: 1px solid #003046;  
}
.tb li span {
  display:inline-block;
  font-size: 16px;
  font-weight: bold;
}
#box-scroll{
  /* height: 550px; */
  overflow: hidden;    
}
#box-scroll ul {
  padding: 0;
}
#box-scroll li{
  list-style: none;
  line-height: 40px;
  height: 40px;
  display: block;
  margin: 4px 0;
  padding: 0 5px;
  font-size: 14px;
  /* border-bottom: 1px dashed #003046;*/
  border-bottom: 1px dashed #aaa;
}
#box-scroll li span {
  display:inline-block;
  font-size: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.mb{
  margin-bottom: 10px;
}
.anim{
  transition: all .5s;
  margin-top: -20px;
}

.hztj div{
  padding: 16px 0;
  color: #000;
  font-size: 24px;
  font-weight: 800;  
}
.hztj div span{
  color: #ffaa00;
  font-size: 34px;
  font-weight: 800;
}
</style>