<template>
  <!-- 图片轮播 -->
  <div class="ad"> 
    <!-- <img :src="require('@/assets/images/ad11.png')" />    -->
    <v-homesimage />
  </div>
  
  <div class="ptb">
    <div class="flex-between mb_15">
      <div class="title">年交易统计</div>
      <!-- <router-link to="#" class="a_tit">更多 &gt;</router-link> -->
    </div>
    <el-card :body-style="{ padding: '0px' }" class="card1"> 
      <div class="flex-between">
        <div style="width: 45%; padding: 20px;"><v-homeshopname /></div>
        <div style="width: 55%; padding: 20px;"><v-hometrade /></div>
      </div>    
    </el-card>
  </div>

  <div style="margin-bottom: 30px;">
    <div class="flex-between mb_15">
      <div class="title">今日实时交易</div>
      <!-- <router-link to="#" class="a_tit">更多 &gt;</router-link> -->
    </div>
    <el-card :body-style="{ padding: '0px' }" class="card1"> 
      <v-Top ref="indexTop" />
    </el-card>
  </div>

</template>

<script>
// import Head from '@/components/vhead'
// import Foot from '@/components/vfoot'
import homesimage from '@/components/home-image'
import homeshopname from '@/components/home-shopname'
import hometrade from '@/components/home-trade'
import Top from '@/components/top'
// import {formatDate} from '@/utils/datetime_utils.js'
export default {
  components: {
    // 'v-head': Head,
    // 'v-foot': Foot,
    'v-homesimage': homesimage,
    'v-homeshopname': homeshopname,
    'v-hometrade': hometrade,
    'v-Top': Top
  },
  data () {
    return {
      imgData: ['@/assets/images/ad1.jpg', '@/assets/images/ad2.jpg'],
      // imgList: [], // 读取的轮播图片列
      // imgData: [], // 显示的轮播图片列
      messageData: [], // 通知公告
      // imgLoading: false
      contentData1: [], // 政策法规
      contentData2: [] // 学习资源      
    }
  },
  mounted () {
    // console.log('pic=', this.imgData)
    // this.getImageList(2).then((result) => {          
    //   this.imgList = result
    // })
    // 滚动图片
    // this.getadPic() 
    // this.getImageList(2)

    // 常见问题 1588066290794504194
    // this.getContentList('1588066290794504194', 8).then((result) => {          
    //   this.contentData1 = result
    // })
    // // 学习资源
    // this.getContentList('1668149071004340226', 8).then((result) => {
    //   this.contentData2 = result
    // })
    // this.getMessageList()
  },
  methods: {
    // types：logo:1 轮播图片:2
    async getImageList(types) {
      const params = '?status=1&types=' + types + '&size=10&current=1' 
      const { data: ret } = await this.$http.get(this.$api.imgPage + params)
      console.log('ret=', params, ret)
      if (ret.code === 1) {
        if (ret.data === null) {          
          this.$message.warning(ret.msg)
          return
        }        
        // ret.data.records.forEach(p => {          
        //   p.img = p.fileId ? this.$api.showIdImg + p.fileId : this.$api.defaultImg      
        // })
        // return ret.data.records
        ret.data.records.forEach(p => {          
          var img = p.fileId ? this.$api.showIdImg + p.fileId : this.$api.defaultImg 
          // this.imgList.push(img)
          this.imgList.push({
            img: img,
            remark: p.remark ? p.remark : ''
          })  
        }) 
        console.log('this.imgList=', this.imgList)       
        // this.$nextTick(()=>{
        //   this.imgData = this.imgList
        // })
        // 获取base64编码图片保存在本地
        this.getImg(this.imgList)
      }              
    },
    // 获取本地保存的ad,base64编码图片
    getadPic() {
      var imgs = null
      try {
        imgs = this.$storage.local.get('adPic')
      }
      catch (e) {
        //console.log("Storage failed: " + e)
        imgs = ['/images/ad1.jpg']
      }      
      this.imgData = imgs      
    }, 
    // 获取base64编码图片保存在本地
    async getImg(dList) {
      var imgs = []
      // console.log('dList=', dList) // dList.length
      for (var i = 0; i < dList.length; i++) {
        // var url = 'http://localhost:8081/images/ad1.jpg'
        var img = await this.url2DataURL(dList[i].img)
        // imgs.push(img)
        imgs.push({
          img: img,
          remark: dList[i].remark
        })
      }
      // this.$nextTick(() => {
      this.imgData = imgs
      // })
      // 本地保存
      this.$storage.local.set('adPic', imgs)
    },   
    async url2DataURL(url) {
      var img = await this.url2Image(url)      
      var canvas = this.image2Canvas(img)
      var dataURL = canvas.toDataURL(canvas)
      return dataURL
    },
    // Url/Data-URL/Object-URL 转 Image
    url2Image(url) {
      return new Promise(resolve => {
        const image = new Image() 
        image.crossOrigin = "anonymous" 
        image.onload = () => {
          resolve(image)
        }
        image.src = url
      })
    },
    // Image 转 Canvas
    image2Canvas(image) {
      const canvas = document.createElement('canvas')
      const ctx = canvas.getContext('2d')
      canvas.width = image.width
      canvas.height = image.height
      ctx.drawImage(image, 0, 0)
      return canvas
    },
    // 内容
    // async getContentList (id, size) {
    //   const params = '?categoryId=' + id + '&size=' + size
    //   const { data: ret } = await this.$http.get(this.$api.contentPageVue + params)     
    //   // console.log('ContentList=', ret)
    //   if (ret.code === 1) {
    //     if (ret.data === null) {          
    //       this.$message.warning(ret.msg)
    //       return
    //     }
    //     return ret.data.records
    //   }
    // },
    // 通知公告
    // async getMessageList() {
    //   const params = '?status=1&size=10&current=1' 
    //   const { data: ret } = await this.$http.get(this.$api.postmessagePage + params)
    //   // console.log('ret=', params, ret)
    //   if (ret.code === 1) {
    //     this.messageData = ret.data.records
    //   } else {
    //     this.$message.error(ret.msg)        
    //   }         
    // },
    // // 显示时间格式'yyyy-MM-dd hh:mm:ss'
    // formatDate(time){
    //   return formatDate(time,'yyyy-MM-dd')
    // }
  },  
  // watch: {
  //   // 监听路由query中key的变化
  //   $route (to, from) {
  //     console.log('from=', from)
  //     console.log('to=', to)
  //     // 路径有变化时刷新页面
  //     // if(from.fullPath !== to.fullPath) {
  //     //   this.$router.go(0)
  //     // }        
  //   }
  // }
}
</script>

<style>
.body_bgcolor {
  background-color: #F2F3F7
}
.ad {
  margin-top: 20px;
}
.ad .el-carousel__container {
  height: 500px;
}
.ad img{
  width: 100%;
  height: 500px;
}
/* .ad .img-bg {
  width: 100%;
  height: 99.9%;
  border: 1px solid #999;
  background: url('../assets/images/ad11.png') center no-repeat;
} */
.message {
  margin: 32px 0;
}
.message .el-carousel__container {
  height: 28px;
}
.message-title {
  margin: 0 16px;
  font-size: 18px;
  font-weight: bold;
  float: left;
}
.message-title a.tit {
  font-size: 18px;
  font-weight: bold;  
}
.message span {
  font-size: 13px;
  color: #999;
}
.message-list {
 line-height: 28px; 
}
.card1{
  width: 100%;
  /* padding: 20px 30px;   */
}
.card1 img {
  width: 100%;
  height: 172px;
}
.card1-body{
  padding: 15px;
  line-height: 180%;
  font-size: 14px;
}
.card1-title{
  padding: 15px;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
}
.nlist1{
  padding: 32px 24px; 
  height: 230px; 
}
.nlist1 ul {
  margin:0;
  padding:0;
}
.nlist1 li {
  list-style-type: none;
  padding: 5px 0;
  letter-spacing: 1px;  
}
.nlist1 li a {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.nlist1 li a span {
  padding-right: 5px;
  font-size: 14px;
  font-weight: bold;
  color: #ccc;
}
.nlist1 li a .a1  {
  padding: 0;
  font-weight: bold;
  color: #1D5CB9;
}
.nlist1 li time {
  width: 100px;
  padding-top: 5px;
  font-size: 12px;
  color: #aaa;
  text-align: right;
}
.message a {
  display:block;
  font-size:15px;
}
</style>