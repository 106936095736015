<template>
  <div class="com-container">
    <div ref="echart_ref" class="com-chart"></div>
  </div>
</template>

<script>
import {get12Month} from '@/utils/datetime_utils.js'
export default {
  data () {
    return {
      // chartInstance: null
    }
  },
  mounted () {
    this.initChart()
    this.getData()
    // 监听窗口变化，发生变化调用this.screenAdapter函数
    window.addEventListener('resize', this.screenAdapter)
    // 在页面加载完成时主动进行屏幕适配
    this.screenAdapter()
  },
  // unmount () {
  //   window.removeEventListener('resize', this.screenAdapter)
  // },
  methods: {    
    // 图表数据 按月份统计本年的营业额和交易笔数
    async getData () {
      var en = {
        position: "merchantTrade_queryMerchantTradeSummaryByMonth"        
      }
      const { data: res } = await this.$http.post(this.$api.querySql, en) 
      // console.log('按月份统计本年的营业额和交易笔数', res)
      if (res.state.code === 10000) {
        this.getEchartData(res.body)
      } else {
        this.$message.error(res.state.message)
      }
    },
    getEchartData (list) {
      // 获取前12条数据
      var allData = list.slice(0,12) 
      allData.forEach(p => {
        p.tradeMonth = p.tradeMonth.slice(0, 4) + '年' + p.tradeMonth.slice(4) + '月'
      })
      // 获取当前月最近的12个月
      var months = get12Month()
      var datas = []
      months.forEach(p => {
        const result = allData.find(c => c.tradeMonth === p)
        if (result) {
          datas.push(result)
        } else {
          datas.push({
            tradeMonth: p,
            tradeCount: 0,
            tradeAmount: 0
          })
        }
      })
      var tradeMonth = datas.map(c => c.tradeMonth)
      var tradeCounts = datas.map(c => c.tradeCount)
      var tradeAmounts = datas.map(c => c.tradeAmount)
      this.updateEchart(tradeMonth, tradeCounts, tradeAmounts)
    },
    // 初始化图表
    initChart () {
      // this.chartInstance = markRaw(this.$echarts.init(this.$refs.chart_ref))
      this.chartInstance = this.$echarts.init(this.$refs.echart_ref)
      const initOption = {
        title: {
          text: '近一年交易统计'
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            crossStyle: {
              color: '#999'
            }
          }
        },
        grid: {
          // right: '20%' 
          top: 100,        
          bottom: 45
        },
        // toolbox: {
        //   feature: {
        //     dataView: { show: true, readOnly: false },
        //     magicType: { show: true, type: ['line', 'bar'] },
        //     restore: { show: true },
        //     saveAsImage: { show: true }
        //   }
        // },
        legend: {
          top: 35,
          data: ['交易金额', '交易笔数']
        },
        xAxis: [
          {
            type: 'category',
            axisPointer: {
              type: 'shadow'
            },
            axisLabel: { interval: 0, rotate: 30 }
          }
        ],
        yAxis: [
          {
            type: 'value',
            name: '交易金额（元）',
            // min: 0,
            // max: 25,
            // interval: 100
          },
          {
            type: 'value',
            name: '交易笔数',
            // min: 0,
            // max: 25,
            // interval: 100,
            // axisLabel: {
            //   formatter: '{value} ml'
            // }
          }
        ],
        series: [  
          {
            name: '交易金额',
            type: 'bar',
            tooltip: {
              valueFormatter: function (value) {
                return value + ' 元'
              }
            }
          },
          {
            name: '交易笔数',
            type: 'line',
            yAxisIndex: 1            
          }
        ]
      }
      this.chartInstance.setOption(initOption)      
    },
    // 更新图表
    updateEchart (tradeMonth, tradeCounts, tradeAmounts) {
      const dataOption = {
        xAxis: {
          data: tradeMonth            
        },
        series: [
          {
            data: tradeAmounts
          },
          {
            data: tradeCounts
          }
        ]
      }
      this.chartInstance.setOption(dataOption)
    },
    // 当浏览器的大小发生变化的时候，会调用的方法，来完成屏幕的适配
    screenAdapter () {
      // this.$refs.seller_ref.offsetWidth 获取图表容器的宽度
      // const titleFontSize = this.$refs.order_ref.offsetWidth / 100 * 3.6
      // 与分辨率大小相关的配置项
      // const adapterOption = {
      //   // title: {
      //   //   textStyle: {
      //   //     fontSize: titleFontSize
      //   //   }
      //   // },
      //   series: [
      //     // {
      //     //   // 柱的宽度
      //     //   barWidth: titleFontSize,
      //     //   itemStyle: {
      //     //     // 柱的圆角
      //     //     barBorderRadius: [titleFontSize / 2, titleFontSize / 2, 0, 0]
      //     //   }
      //     // }
      //   ]
      // }
      // this.chartInstance.setOption(adapterOption)
      // 调用图表对象的resize才能产生效果
      this.chartInstance.resize()
    }
  }
}
</script>

<style>

</style>